import React, { useState, useEffect } from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { getIaqDataByFloorId, getIaqDataByZoneId } from "./request";
import "./Deviceaction.css";
import ResponsiveChart from "./chart";
import Chip from "@mui/material/Chip";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Stack from "@mui/material/Stack";
import Button_2 from "@mui/material/Button";
import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 76,
  lineHeight: "60px",
}));

export function DeviceAction() {
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [floorID, setFloorID] = useState<string | null>(
    localStorage.getItem("floorID")
  );
  const [showTimeFilters, setShowTimeFilters] = useState(false);

  const content = (
    <div>
      <p>This is the popup message!</p>
    </div>
  );

  const getDataList = async (floor_Id: any) => {
    localStorage.setItem("floorID", floor_Id);
    try {
      const { data } = await getIaqDataByFloorId(floor_Id);
      if (data) {
        setDataList(data);
        setFilterDataList(data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    let floorIntervalId;

    if (floorID) {
      floorIntervalId = setInterval(() => {
        getDataList(floorID);
      }, 30000);
    }

    // Clear the floorID interval when the component unmounts or when floorID changes
    return () => clearInterval(floorIntervalId);
  }, [floorID, localStorage.getItem("floorID")]);

  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string | null>(
    null
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    handleClose(); // Close dialog when an option is clicked
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openformenubutton = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseformenubutton = () => {
    setAnchorEl(null);
  };
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
    }[];
  } | null>(null);

  useEffect(() => {
    if (chartData === null) {
      console.log("Setting chartData to data1");
      setChartData(data1);
    }
  }, [chartData]);

  const data1 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "Overall Index",
        data: [50, 75, 150, 50, 102, 150, 100],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#84DE02",
      },
    ],
  };

  const data2 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "CO₂",
        data: [100, 125, 143, 96, 66, 87, 67],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FF5733",
      },
    ],
  };

  const data3 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "Humidity",
        data: [80, 123, 110, 114, 88, 122, 65],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#7366FF",
      },
    ],
  };

  const data4 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "PM10",
        data: [56, 66, 77, 78, 99, 100, 56],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FFC300",
      },
    ],
  };

  const data5 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "PM2.5",
        data: [50, 79, 145, 78, 56, 76, 111],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FF5733",
      },
    ],
  };

  const data6 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "REST Viral Index",
        data: [80, 85, 90, 85, 88, 90, 85],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#34EB7E",
      },
    ],
  };

  const data7 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "Temperature",
        data: [67, 56, 98, 51, 66, 111, 143],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FFC300",
      },
    ],
  };

  const data8 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "TVOC",
        data: [77, 65, 70, 97, 87, 111, 123],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#7366FF",
      },
    ],
  };
  const handleButtonClick = (data) => {
    setChartData((prevData) => {
      if (!prevData) {
        return {
          labels: data.labels,
          datasets: [...data.datasets],
        };
      }

      return {
        labels: prevData.labels, 
        datasets: [...prevData.datasets, ...data.datasets],
      };
    });
  };

  return (
    <>
      <div
        className="d-flex flex-wrap flex-stack mb-6 "
        style={{ display: "none" }}
      >
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3  py-lg-0">
            <div
              id="kt_app_toolbar_container"
              className="app-container-fluid d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  IAQ Management
                </h1>

                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-0">
                  <li className="breadcrumb-item text-muted">
                    <a
                      href="/modules/device-management"
                      className="text-muted text-hover-primary"
                    >
                      Back to Devices{" "}
                    </a>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"></span>
                  </li>

                  <li className="breadcrumb-item text-muted">IAQ Management</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container-fluid">
              <div className="d-flex h-95px mt-5 ">
                <Box sx={{ display: "grid", gap: "20px" }}>
                  <Chip
                    avatar={
                      <FiberManualRecordIcon
                        style={{
                          color: "green",
                          fontSize: "1rem",
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    }
                    label={<span style={{ color: "green" }}>Online</span>} // Change label color to green
                    style={{
                      backgroundColor: "#c3f6c3",
                      borderRadius: "2px",
                      height: "1cm",
                      fontSize: "16px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                    }}
                  />
                  <div className="left-container">
                    <h6 className="text-muted text">Device Name</h6>
                    <h1>1F-IAQ-1</h1>
                  </div>
                </Box>
              </div>
              <Divider sx={{ border: "1px solid grey", mt: 3 }} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      bgcolor: "background.default",
                      display: "grid",
                      gridTemplateColumns: {
                        xs: "repeat(3, 1fr)",
                        md: "repeat(6, 1fr)",
                      }, // Adjust columns as needed
                      gap: 2,
                    }}
                  >
                    <Item elevation={7}>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 10px",
                        }}
                      >
                        Overall Index
                        <HelpOutlineIcon sx={{ fontSize: "15px" }} />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "5px",
                        }}
                      >
                        <Typography variant="h4" sx={{ color: "orange" }}>
                          <b> 62</b>
                        </Typography>
                        <Typography variant="h6">US</Typography>
                      </Typography>
                    </Item>
                    <Item elevation={7}>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 10px",
                        }}
                      >
                        CO 2
                        <HelpOutlineIcon sx={{ fontSize: "15px" }} />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "5px",
                        }}
                      >
                        <Typography variant="h4" sx={{ color: "orange" }}>
                          <b> 1121</b>
                        </Typography>
                        <Typography variant="h6">ppm </Typography>
                      </Typography>
                    </Item>
                    <Item elevation={7}>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 10px",
                        }}
                      >
                        PM 10
                        <HelpOutlineIcon sx={{ fontSize: "15px" }} />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "5px",
                        }}
                      >
                        <Typography variant="h4" sx={{ color: "green" }}>
                          <b> 8 </b>
                        </Typography>
                        <Typography variant="h6">µg/m3</Typography>
                      </Typography>
                    </Item>
                    <Item elevation={7}>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 10px",
                        }}
                      >
                        PM 2.5
                        <HelpOutlineIcon sx={{ fontSize: "15px" }} />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "5px",
                        }}
                      >
                        <Typography variant="h4" sx={{ color: "green" }}>
                          <b> 6</b>
                        </Typography>
                        <Typography variant="h6">µg/m3</Typography>
                      </Typography>
                    </Item>
                    <Item elevation={7}>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 10px",
                        }}
                      >
                        TVOC
                        <HelpOutlineIcon sx={{ fontSize: "15px" }} />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "5px",
                        }}
                      >
                        <Typography variant="h4" sx={{ color: "orange" }}>
                          <b> 313</b>
                        </Typography>
                        <Typography variant="h6">ppb</Typography>
                      </Typography>
                    </Item>
                    <Item elevation={7}>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "5px 10px",
                        }}
                      >
                        REST VIRAL INDEX
                        <HelpOutlineIcon sx={{ fontSize: "15px" }} />
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "5px",
                        }}
                      >
                        <Typography variant="h4" sx={{ color: "orange" }}>
                          <b> 50</b>
                        </Typography>
                        <Typography variant="h6">%</Typography>
                      </Typography>
                    </Item>
                    <Item
                      elevation={7}
                      sx={{
                        display: "grid",
                        textAlign: "start",
                        padding: "10px 0",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          ml: 2,
                        }}
                      >
                        Temperature 25.13%
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          color: "black",
                          ml: 2,
                        }}
                      >
                        Humidity 47%
                      </Typography>
                    </Item>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {/* Top  */}
                <Box sx={{ display: "flex", gap: "1cm" }}>
                  <Box>
                    <span style={{ fontWeight: "700", color: "grey" }}>
                      Last Updated <span>4.00 PM</span>
                    </span>
                    <h2>Air Quality History</h2>
                  </Box>

                  <Box>
                    
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Tooltip title="">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: 1,
                            bgcolor: "#bbdefb",
                          }}
                        >
                          <MoreVertIcon />
                        </Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openformenubutton}
                    onClose={handleCloseformenubutton}
                    onClick={handleCloseformenubutton}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          borderRadius: "8px",
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,

                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={handleClose}
                      sx={{ fontFamily: "Inter" }}
                    >
                      Export as PNG
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={handleClose}
                      sx={{ fontFamily: "Inter" }}
                    >
                      Export as CSV
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>

          <Box sx={{marginTop:3}}>
          <ResponsiveChart chartData={chartData} />
          </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  color: "black!important",
                }}
              >
                <Button_2
                  variant="outlined"
                  className="btn_1"
                  onClick={() => handleButtonClick(data1)}
                  sx={{
                    width: "4cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                >
                  Overall Index
                </Button_2>
                <Button_2
                  variant="outlined"
                  className="btn_2"
                  sx={{
                    width: "1cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  onClick={() => handleButtonClick(data2)}
                >
                  CO₂
                </Button_2>
                <Button_2
                  variant="outlined"
                  className="btn_3"
                  sx={{
                    width: "2cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  onClick={() => handleButtonClick(data3)}
                >
                  Humidity
                </Button_2>
                <Button_2
                  variant="outlined"
                  sx={{
                    width: "1cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  className="btn_4"
                  onClick={() => handleButtonClick(data4)}
                >
                  PM10
                </Button_2>
                <Button_2
                  variant="outlined"
                  className="btn_5"
                  sx={{
                    width: "1cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  onClick={() => handleButtonClick(data5)}
                >
                  PM2.5
                </Button_2>
                <Button_2
                  variant="outlined"
                  className="btn_6"
                  sx={{
                    width: "4cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  onClick={() => handleButtonClick(data6)}
                >
                  REST Viral Index
                </Button_2>
                <Button_2
                  variant="outlined"
                  className="btn_7"
                  sx={{
                    width: "4cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  onClick={() => handleButtonClick(data7)}
                >
                  Temperature
                </Button_2>
                <Button_2
                  variant="outlined"
                  className="btn_8"
                  sx={{
                    width: "1cm",
                    height: "1.2cm",
                    textTransform: "capitalize",
                    fontFamily: "Inter",
                    color: "black",
                    border: "2px solid skyblue",
                    backgroundColor: "white",
                  }}
                  onClick={() => handleButtonClick(data8)}
                >
                  TVOC
                </Button_2>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeviceAction;
